/* Formatting for html-tables */
@use "./var" as *;

.table-wrapper {
	overflow: hidden;
	overflow-x: scroll;
}

@media screen and (max-width: $small-screen-size) {
	/* Add small note below the table to indicate that you can scroll horizontally */
	.table-wrapper::after {
		content: "Scroll horizontally to see more";
		display: block;
		text-align: center;
		font-size: 0.8rem;
		color: $scheme-dark-gray;
		margin: 0.5rem 0;
	}

	.table-wrapper > table {
		margin-bottom: 0;
	}
}

table {
	border-color: black;
	border-collapse: collapse;
	border-spacing: 0;
	line-height: 1.2em;
	margin-top: 1rem;
	margin-bottom: 1rem;

	& thead tr th {
		color: $scheme-primary;
		overflow: hidden;
		word-break: normal;
		font-weight: 700;
		border-width: 1px 1px 2px 1px;
	}

	& tbody tr td {
		border-width: 1px;
	}

	& tbody tr td,
	& thead tr th {
		text-align: left;
		vertical-align: top;
		padding: 1rem 0.8rem;
		line-height: 1.5em;
		border-color: rgba(25, 25, 28, 0.2);
		border-style: solid;
		border-collapse: collapse;
	}
}
